<template>
  <v-data-table
    class="ma-0 pa-0 okaml_cursor_pointer"
    dense
    multi-sort
    :headers="headers"
    :items="subjectItems"
    :mobile-breakpoint="0"
    :loading="!callResult.finished"
    :options.sync="requestParams.pageOptions"
    :server-items-length="totalItems"
    :footer-props="{ itemsPerPageOptions: [10] }"
    :item-class="i => cardId === String(i.id) ? 'greyee' : ''"
    @click:row="updateCardId"
  >
    <template #[`item.subjectname`]="{ item }">
      <span style="white-space: nowrap; font-weight: 500">{{ item.subjectname }}</span>
    </template>
    <template #[`item.subjectBirthDate`]="{ item }">
      <span style="white-space: nowrap">{{ $utils.formatDateWithoutTime(item.subjectBirthDate) }}</span>
    </template>
    <template #[`item.totalSpentAmount`]="{ item }">
      <span style="white-space: nowrap">{{ $utils.formatCurrency(item.totalSpentAmount) }}</span>
    </template>
    <template #[`item.warning`]="{ item }">
      <span
        v-if="item.warning"
        class="error--text"
        style="font-weight: 500;"
      >{{ $t('$l.app.ynYes') }}</span>
      <span v-else>{{ $t('$l.app.ynNo') }}</span>
    </template>
    <template #[`item.isSanctioned`]="{ item }">
      <span
        v-if="item.isSanctioned === 'YES'"
        class="error--text"
        style="font-weight: 500;"
      >{{ $t('$l.app.ynYes')
      }}</span>
      <span
        v-if="item.isSanctioned === 'NO'"
        class="success--text"
        style="font-weight: 500;"
      >{{ $t('$l.app.ynNo')
      }}</span>
      <v-icon
        v-if="item.isSanctioned === 'UNKNOWN'"
        class="warning--text"
        style="font-weight: 500;"
        small
      >
        mdi-comment-question-outline
      </v-icon>
    </template>
    <template #[`item.isPep`]="{ item }">
      <span
        v-if="item.isPep === 'YES'"
        class="error--text"
        style="font-weight: 500;"
      >{{ $t('$l.app.ynYes')
      }}</span>
      <span
        v-if="item.isPep === 'NO'"
        class="success--text"
        style="font-weight: 500;"
      >{{ $t('$l.app.ynNo')
      }}</span>
      <v-icon
        v-if="item.isPep === 'UNKNOWN'"
        class="warning--text"
        style="font-weight: 500;"
        small
      >
        mdi-comment-question-outline
      </v-icon>
    </template>
    <template #[`item.countryWarning`]="{ item }">
      <span
        v-if="item.countryWarning"
        class="error--text"
        style="font-weight: 500;"
      >{{ $t('$l.app.ynYes') }}</span>
      <span v-else>{{ $t('$l.app.ynNo') }}</span>
    </template>
    <template #[`item.cardIdWarning`]="{ item }">
      <span
        v-if="item.cardIdWarning"
        class="error--text"
        style="font-weight: 500;"
      >{{ $t('$l.app.ynYes') }}</span>
      <span v-else>{{ $t('$l.app.ynNo') }}</span>
    </template>
    <template #[`item.firstChecked`]="{ item }">
      <span style="white-space: nowrap">{{ $utils.formatDateWithoutTime(item.firstChecked) }}</span>
    </template>
    <template #[`item.lastChecked`]="{ item }">
      <span style="white-space: nowrap">{{ $utils.formatDate(item.lastChecked) }}</span>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: 'SubjectList',
  props: {
    callResult: {
      type: Object,
      required: true
    },
    subjectItems: {
      type: Array,
      required: true
    },
    requestParams: {
      type: Object,
      required: true
    },
    totalItems: {
      type: Number,
      required: true
    },
    cardId: {
      type: String,
      required: false,
      default: null
    }
  },
  computed: {
    headers () {
      return [
        { text: this.$t('$l.api.subjects.subjectname'), value: 'subjectname', sortable: true },
        { text: this.$t('$l.api.subjects.subjectBirthDate'), value: 'subjectBirthDate', sortable: true },
        { text: this.$t('$l.api.subjects.totalChecks'), value: 'totalChecks', sortable: true },
        { text: this.$t('$l.api.subjects.warning'), value: 'warning', sortable: true },
        { text: this.$t('$l.api.subjects.pepPerson'), value: 'isPep', sortable: true },
        { text: this.$t('$l.api.subjects.sanctionPerson'), value: 'isSanctioned', sortable: true },
        { text: this.$t('$l.api.subjects.cardIdWarning'), value: 'cardIdWarning', sortable: true },
        { text: this.$t('$l.api.subjects.countryWarning'), value: 'countryWarning', sortable: true },
        { text: this.$t('$l.api.subjects.nrCardIds'), value: 'nrCardIds', sortable: true },
        { text: this.$t('$l.api.subjects.nrIds'), value: 'nrIds', sortable: true },
        { text: this.$t('$l.api.subjects.nrCountries'), value: 'nrCountries', sortable: true },
        { text: this.$t('$l.api.subjects.totalSpentAmount'), value: 'totalSpentAmount', sortable: true },
        { text: this.$t('$l.api.subjects.nrAttachments'), value: 'nrAttachments', sortable: true },
        { text: this.$t('$l.api.subjects.nrNotes'), value: 'nrNotes', sortable: true },
        { text: this.$t('$l.api.subjects.firstChecked'), value: 'firstChecked', sortable: true },
        { text: this.$t('$l.api.subjects.lastChecked'), value: 'lastChecked', sortable: true },
        { text: this.$t('$l.api.subjects.lastKnownCardId'), value: 'lastKnownCardId', sortable: true },
        { text: this.$t('$l.api.subjects.lastKnownId'), value: 'lastKnownId', sortable: true },
        { text: this.$t('$l.api.subjects.lastKnownCountry'), value: 'lastKnownCountry', sortable: true }
        // hidden fields: id, created, lastModified, version, apiHeadQuarter, data
      ]
    }
  },
  watch: {
    'requestParams.pageOptions' (newValue, oldValue) {
      this.requestParams.page = this.requestParams.pageOptions.page
      this.requestParams.sortBy = this.requestParams.pageOptions.sortBy
      this.requestParams.sortDesc = this.requestParams.pageOptions.sortDesc
      this.$emit('subjectListParamsChanged')
    }
  },
  methods: {
    updateCardId (subjectListItem) {
      this.$emit('setCardId', subjectListItem.id)
    }
  }
}
</script>
