<template>
  <ApiSubjectCardListLayout />
</template>

<script>
import ApiSubjectCardListLayout from '@/components/api/apiSubject/ApiSubjectCardListLayout'

export default {
  name: 'ApiSubjectCardList',
  components: {
    ApiSubjectCardListLayout
  }
}
</script>
