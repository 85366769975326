var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"ma-0 pa-0 okaml_cursor_pointer",attrs:{"dense":"","multi-sort":"","headers":_vm.headers,"items":_vm.subjectItems,"mobile-breakpoint":0,"loading":!_vm.callResult.finished,"options":_vm.requestParams.pageOptions,"server-items-length":_vm.totalItems,"footer-props":{ itemsPerPageOptions: [10] },"item-class":function (i) { return _vm.cardId === String(i.id) ? 'greyee' : ''; }},on:{"update:options":function($event){return _vm.$set(_vm.requestParams, "pageOptions", $event)},"click:row":_vm.updateCardId},scopedSlots:_vm._u([{key:"item.subjectname",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"white-space":"nowrap","font-weight":"500"}},[_vm._v(_vm._s(item.subjectname))])]}},{key:"item.subjectBirthDate",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm.$utils.formatDateWithoutTime(item.subjectBirthDate)))])]}},{key:"item.totalSpentAmount",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm.$utils.formatCurrency(item.totalSpentAmount)))])]}},{key:"item.warning",fn:function(ref){
var item = ref.item;
return [(item.warning)?_c('span',{staticClass:"error--text",staticStyle:{"font-weight":"500"}},[_vm._v(_vm._s(_vm.$t('$l.app.ynYes')))]):_c('span',[_vm._v(_vm._s(_vm.$t('$l.app.ynNo')))])]}},{key:"item.isSanctioned",fn:function(ref){
var item = ref.item;
return [(item.isSanctioned === 'YES')?_c('span',{staticClass:"error--text",staticStyle:{"font-weight":"500"}},[_vm._v(_vm._s(_vm.$t('$l.app.ynYes')))]):_vm._e(),(item.isSanctioned === 'NO')?_c('span',{staticClass:"success--text",staticStyle:{"font-weight":"500"}},[_vm._v(_vm._s(_vm.$t('$l.app.ynNo')))]):_vm._e(),(item.isSanctioned === 'UNKNOWN')?_c('v-icon',{staticClass:"warning--text",staticStyle:{"font-weight":"500"},attrs:{"small":""}},[_vm._v(" mdi-comment-question-outline ")]):_vm._e()]}},{key:"item.isPep",fn:function(ref){
var item = ref.item;
return [(item.isPep === 'YES')?_c('span',{staticClass:"error--text",staticStyle:{"font-weight":"500"}},[_vm._v(_vm._s(_vm.$t('$l.app.ynYes')))]):_vm._e(),(item.isPep === 'NO')?_c('span',{staticClass:"success--text",staticStyle:{"font-weight":"500"}},[_vm._v(_vm._s(_vm.$t('$l.app.ynNo')))]):_vm._e(),(item.isPep === 'UNKNOWN')?_c('v-icon',{staticClass:"warning--text",staticStyle:{"font-weight":"500"},attrs:{"small":""}},[_vm._v(" mdi-comment-question-outline ")]):_vm._e()]}},{key:"item.countryWarning",fn:function(ref){
var item = ref.item;
return [(item.countryWarning)?_c('span',{staticClass:"error--text",staticStyle:{"font-weight":"500"}},[_vm._v(_vm._s(_vm.$t('$l.app.ynYes')))]):_c('span',[_vm._v(_vm._s(_vm.$t('$l.app.ynNo')))])]}},{key:"item.cardIdWarning",fn:function(ref){
var item = ref.item;
return [(item.cardIdWarning)?_c('span',{staticClass:"error--text",staticStyle:{"font-weight":"500"}},[_vm._v(_vm._s(_vm.$t('$l.app.ynYes')))]):_c('span',[_vm._v(_vm._s(_vm.$t('$l.app.ynNo')))])]}},{key:"item.firstChecked",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm.$utils.formatDateWithoutTime(item.firstChecked)))])]}},{key:"item.lastChecked",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm.$utils.formatDate(item.lastChecked)))])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }