<template>
  <v-container fluid>
    <basic-card
      title="$l.api.subjects.apiSubjectCardList"
      class="mt-9"
    >
      <app-api-call
        class="ma-0 pa-0 mb-3"
        :call-result="callResult"
      />
      <SubjectListFilter
        :request-params="requestParams"
        @subjectListParamsChanged="subjectListParamsChanged"
      />

      <SubjectList
        :call-result="callResult"
        :subject-items="subjectItems"
        :request-params="requestParams"
        :total-items="totalItems"
        :card-id="cardId"
        @subjectListParamsChanged="subjectListParamsChanged"
        @setCardId="setCardId"
      />

      <SubjectCard
        v-if="cardId !== null && cardId !== '0'"
        :key="cardId"
        :card-id="cardId"
        :api-affiliate="apiAffiliate"
        @subjectListParamsChanged="subjectListParamsChanged"
      />
    </basic-card>
  </v-container>
</template>

<script>
import SubjectList from '@/components/api/apiSubject/SubjectList.vue'
import SubjectListFilter from '@/components/api/apiSubject/SubjectListFilter.vue'
import SubjectCard from '@/components/api/apiSubject/SubjectCard.vue'

export default {
  name: 'ApiSubjectCardListLayout',
  components: { SubjectList, SubjectListFilter, SubjectCard },
  data () {
    return {
      callResult: { finished: false, authorized: true, error: null, info: null },
      subjectItems: [],
      requestParams: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['lastChecked'],
        sortDesc: [false],
        subjectSubstring: '',
        pageOptions: {},
        showWarningsOnly: false,
        dateFrom: '',
        dateTo: ''
      },
      totalItems: 0,
      cardId: null,
      apiAffiliate: ''
    }
  },
  created () {
    this.loadSubjectListData()
  },
  methods: {
    loadSubjectListData (cardId) {
      this.callResult.finished = false
      this.callResult.error = null
      this.callResult.info = this.$i18n.t('$l.info.loadingData')
      this.apiAffiliate = this.requestParams.apiAffiliate

      const payload = JSON.parse(JSON.stringify(this.requestParams))

      if (this.requestParams.dateFrom !== '') {
        payload.dateFrom = new Date(this.requestParams.dateFrom + 'T00:00:00').toISOString()
      }
      if (this.requestParams.dateTo !== '') {
        payload.dateTo = new Date(this.requestParams.dateTo + 'T00:00:00').toISOString()
      }

      this.$xapi.post('apiSubject', payload)
        .then(r => {
          this.subjectItems = r.data.subjectList
          this.totalItems = r.data.count
          if (r.data.subjectList.length > 0) {
            if (cardId === undefined) {
              this.cardId = String(r.data.subjectList[0].id)
            } else {
              this.cardId = String(cardId)
            }
          }
        })
        .catch(e => {
          this.callResult.error = e.response.localizedMessage
        })
        .finally(() => {
          this.callResult.info = null
          this.callResult.finished = true
        })
    },
    subjectListParamsChanged (cardId) {
      this.loadSubjectListData(cardId)
      this.cardId = '0'
    },
    setCardId (id) {
      this.cardId = String(id)
    },
    redirectToSubjectCard (subjectListItem) {
      // redirect to full detail in separate page not used yet
      this.$router.push({ name: 'ApiSubjectCard', params: { cardId: subjectListItem.id } })
    }
  }
}
</script>
