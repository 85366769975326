<template>
  <div>
    <v-btn
      text
      :loading="exportInProgress"
      class="info2 white--text"
      @click="confirmationDialog=true"
    >
      {{ $i18n.t('$l.api.export') }}
      <v-icon
        dark
        right
      >
        mdi-file-excel
      </v-icon>
    </v-btn>

    <v-snackbar
      v-model="snackbar"
      color="error"
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click.stop="snackbar = false"
        >
          {{ $i18n.t('$l.app.close') }}
        </v-btn>
      </template>
    </v-snackbar>

    <basic-dialog
      v-model="confirmationDialog"
      title="$l.api.export"
      save-button
      save-button-label="$l.app.confirm"
      @close-dialog="confirmationDialog=false"
      @save-dialog="DownloadExcelCheckList()"
    >
      <v-container>
        <div class="font-weight-medium">
          {{ this.$i18n.t('$l.api.CSVConfimationText1') }}
        </div>
        <div v-if="requestParams.apiAffiliate">
          {{ $i18n.t('$l.api.apiUserAffiliate') + ": " + requestParams.apiAffiliate }}
        </div>
        <div v-if="requestParams.dateFrom">
          {{ $i18n.t('$l.api.subjects.lastCheckedFrom') + ": " + requestParams.dateFrom }}
        </div>
        <div v-if="requestParams.dateTo">
          {{ $i18n.t('$l.api.subjects.lastCheckedTo') + ": " + requestParams.dateTo }}
        </div>
        <div v-if="requestParams.subjectSubstring">
          {{ $i18n.t('$l.case.subject') + ": " + requestParams.subjectSubstring }}
        </div>
        <div class="font-weight-medium">
          {{ this.$i18n.t('$l.api.CSVConfimationText2') +" "+ $utils.numberWithSpaces(limit) }}
        </div>
      </v-container>
    </basic-dialog>
  </div>
</template>

<script>
import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'

export default {
  name: 'DownloadExcelSubjectList',
  props: {
    requestParams: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      exportInProgress: false,
      confirmationDialog: false,
      snackbar: false,
      snackbarText: this.$i18n.t('$l.api.generatingCSVFailed'),
      limit: 50000
    }
  },
  methods: {
    DownloadExcelCheckList () {
      this.exportInProgress = true
      this.confirmationDialog = false

      const payload = JSON.parse(JSON.stringify(this.requestParams))

      payload.itemsPerPage = this.limit

      if (this.requestParams.dateFrom !== '') {
        payload.dateFrom = new Date(this.requestParams.dateFrom + 'T00:00:00').toISOString()
      }
      if (this.requestParams.dateTo !== '') {
        payload.dateTo = new Date(this.requestParams.dateTo + 'T00:00:00').toISOString()
      }

      this.$xapi.post('apiSubject', payload)
        .then(r => {
          this.createExcelFile(r.data.subjectList)
        })
        .catch(e => {
          this.snackbar = true
          this.exportInProgress = false
        })
        .finally(() => {
        })
    },
    getApiAffiliatepurpose (affiliateVisitPurpose) {
      if (affiliateVisitPurpose) {
        return this.$i18n.t(`$l.api.visitReason.${affiliateVisitPurpose}`)
      } else {
        return ''
      }
    },
    async createExcelFile (data) {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Sheet1')
      worksheet.addRow([
        this.$t('$l.api.subjects.subjectname'),
        this.$t('$l.api.subjects.subjectBirthDate'),
        this.$t('$l.api.subjects.totalChecks'),
        this.$t('$l.api.subjects.warning'),
        this.$t('$l.api.subjects.pepPerson'),
        this.$t('$l.api.subjects.sanctionPerson'),
        this.$t('$l.api.subjects.cardIdWarning'),
        this.$t('$l.api.subjects.countryWarning'),
        this.$t('$l.api.subjects.nrCardIds'),
        this.$t('$l.api.subjects.nrIds'),
        this.$t('$l.api.subjects.nrCountries'),
        this.$t('$l.api.subjects.totalSpentAmount'),
        this.$t('$l.api.subjects.nrAttachments'),
        this.$t('$l.api.subjects.nrNotes'),
        this.$t('$l.api.subjects.firstChecked'),
        this.$t('$l.api.subjects.lastChecked'),
        this.$t('$l.api.subjects.lastKnownCardId'),
        this.$t('$l.api.subjects.lastKnownId'),
        this.$t('$l.api.subjects.lastKnownCountry'),
        this.$t('$l.api.subjects.apiHeadQuarter') ])
      data.forEach((item) => {
        worksheet.addRow([
          item.subjectname,
          item.subjectBirthDate,
          item.totalChecks,
          item.warning,
          item.isPep,
          item.isSanctioned,
          item.cardIdWarning,
          item.countryWarning,
          item.nrCardIds,
          item.nrIds,
          item.nrCountries,
          item.totalSpentAmount,
          item.nrAttachments,
          item.nrNotes,
          item.firstChecked,
          item.lastChecked,
          item.lastKnownCardId,
          item.lastKnownId,
          item.lastKnownCountry,
          item.apiHeadQuarter])
      })
      const blob = await workbook.xlsx.writeBuffer()
      saveAs(new Blob([blob]), 'okaml_subject_list_export.xlsx')

      this.exportInProgress = false
    }
  }
}
</script>
