<template>
  <v-card
    outlined
    class="mb-1"
  >
    <v-row dense>
      <v-col cols="3">
        <v-card-subtitle class="ma-0 pa-0 pa-3 body-1 font-weight-medium">
          {{ $i18n.t('$l.app.filter') }}
        </v-card-subtitle>
      </v-col>
      <v-col align="right">
        <v-btn
          text
          rounded
          class="info2 white--text ma-1"
          @click="resetFilterAndSearchOptions()"
        >
          {{ $i18n.t('$l.api.refresh') }}
          <v-icon small>
            mdi-reload
          </v-icon>
        </v-btn>
        <DownloadExcelSubjectList
          class="d-inline ma-1"
          :request-params="requestParams"
        />
      </v-col>
    </v-row>

    <v-row dense>
      <v-col
        cols="12"
        sm="12"
        md="2"
      >
        <v-combobox
          v-model="requestParams.apiAffiliate"
          class="ma-3"
          dense
          clearable
          :disabled="apiUserAffiliatesLoading"
          :loading="apiUserAffiliatesLoading"
          :items="apiUserAffiliateItems"
          :label="$i18n.t('$l.api.apiUserAffiliate')"
          @change="subjectListParamsChanged"
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="2"
      >
        <v-menu
          ref="dateFromMenu"
          v-model="dateFromMenu"
          :close-on-content-click="false"
          :return-value.sync="requestParams.dateFrom"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="requestParams.dateFrom"
              :label="$i18n.t('$l.api.subjects.lastCheckedFrom')"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              dense
              class="ma-3"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="requestParams.dateFrom"
            no-title
            scrollable
            :locale="$i18n.locale"
          >
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="dateFromMenuCancel"
            >
              {{ $t('$l.app.cancel') }}
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="dateFromMenuOk"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="2"
      >
        <v-menu
          ref="dateToMenu"
          v-model="dateToMenu"
          :close-on-content-click="false"
          :return-value.sync="requestParams.dateTo"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="requestParams.dateTo"
              :label="$i18n.t('$l.api.subjects.lastCheckedTo')"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              dense
              class="ma-3"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="requestParams.dateTo"
            no-title
            scrollable
            :locale="$i18n.locale"
          >
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="dateToMenuCancel"
            >
              {{ $t('$l.app.cancel') }}
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="dateToMenuOk"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="2"
      >
        <v-text-field
          v-model="requestParams.subjectSubstring"
          class="ma-3 pa-0 pb-3"
          prepend-icon="mdi-magnify"
          :label="$i18n.t('$l.case.subject')"
          single-line
          hide-details
          dense
          @change="subjectListParamsChanged"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="3"
      >
        <v-checkbox
          v-model="requestParams.showWarningsOnly"
          class="ma-3 pa-0"
          :label="$i18n.t('warnings only')"
          @change="subjectListParamsChanged"
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import DownloadExcelSubjectList from '@/components/api/apiSubject/DownloadExcelSubjectList.vue'

export default {
  name: 'SubjectListFilter',
  components: { DownloadExcelSubjectList },
  props: {
    requestParams: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      apiUserAffiliateItems: [],
      apiUserAffiliatesLoading: true,
      dateFromMenu: false,
      dateToMenu: false
    }
  },
  created () {
    if (this.$store.state.user.isAuthenticated) {
      this.loadApiAffiliateItems()
    }
  },
  methods: {
    resetFilterAndSearchOptions () {
      this.requestParams.subjectSubstring = ''
      this.requestParams.apiAffiliate = ''
      this.requestParams.dateFrom = ''
      this.requestParams.dateTo = ''
      this.loadApiAffiliateItems()
      this.subjectListParamsChanged()
    },
    dateFromMenuOk () {
      if (this.$refs.dateFromMenu) {
        this.$refs.dateFromMenu.save(this.requestParams.dateFrom)
      }
      this.dateFromMenu = false
      this.subjectListParamsChanged()
    },
    dateFromMenuCancel () {
      if (this.$refs.dateFromMenu) {
        this.requestParams.dateFrom = ''
        this.$refs.dateFromMenu.save('')
      }
      this.dateFromMenu = false
      this.subjectListParamsChanged()
    },
    dateToMenuOk () {
      if (this.$refs.dateToMenu) {
        this.$refs.dateToMenu.save(this.requestParams.dateTo)
      }
      this.dateToMenu = false
      this.subjectListParamsChanged()
    },
    dateToMenuCancel () {
      if (this.$refs.dateToMenu) {
        this.requestParams.dateTo = ''
        this.$refs.dateToMenu.save('')
      }
      this.dateToMenu = false
      this.subjectListParamsChanged()
    },
    subjectListParamsChanged () {
      if (this.requestParams.pageOptions.page !== 1) {
        this.requestParams.pageOptions.page = 1
      } else {
        this.$emit('subjectListParamsChanged')
      }
    },
    loadApiAffiliateItems () {
      this.$xapi.get('apiCheck/getApiAffiliate')
        .then(r => {
          this.apiUserAffiliateItems = r.data
          this.apiUserAffiliateItems.push('')
        })
        .catch(e => {
        })
        .finally(() => {
          this.apiUserAffiliatesLoading = false
        })
    }
  }
}
</script>
